<template>
    <div class="card flex col-12" style="width: 100%;">
        <div class="flex col-10" style="gap: 15px">
            <div class="p-float-label">
                <Calendar class="customCalendarBdpo" v-model="selectedOptions.baseDate" view="month"
                    dateFormat="mm/yy" />
                <label>{{ this.$langGetValue("referenceDate") }}</label>
            </div>
            <div class="p-float-label">

                <MultiSelect class="bdpoSummaryMultiSelect" style="height:100%; min-width: 250px;"
                    v-model="selectedOptions.selectedStatus" :options="bdpoOptions.statusOptions" optionLabel="label"
                    filter>
                    <template #value="slotProps">
                        <span v-if="slotProps.value.length === bdpoOptions.statusOptions.length">
                            {{ this.$langGetValue('all') }}
                        </span>
                        <span v-else>
                            {{slotProps.value.map(item => item.label).join(',')}}
                        </span>
                    </template>
                </MultiSelect>

                <label>Status</label>
            </div>
            <div>
                <Button class="primaryButton" type="button" icon="pi pi-search" :loading="loading" @click="searchList"
                    style="height: 100% !important; width: 3.5rem;" />
            </div>
        </div>
        <div class="col-2 flex justify-content-end">
            <Button icon="pi pi-plus" :label="this.$langGetValue('summaryBdpo')" @click="generateSummaryDialog = true"
                class="primaryButton" :disabled="incidentList == null"
                v-tooltip.top="{ value: this.$langGetValue('generateSummary') }" style="height: 100%" />

        </div>
    </div>
    <div class="card" v-if="incidentList != null">
        <div class="bdpoSummaryDataTableDiv" v-if="incidentList.records.length > 0">
            <DataTable class="bdpoSummaryDataTable" v-model:expandedRows="expandedRows" :value="incidentList.records"
                lazy style="margin-top: 24px" :rows="pages" :rowsPerPageOptions="[5, 10, 20, 50]" paginator
                :totalRecords="totalRecords" @page="paginate($event)" :first="this.currentPage * pages">

                <Column style="width: 3rem; padding-right: 0">
                    <template #body="{ data }">
                        <div style="width: 20px;" v-if="[3, 5, 6, 7].includes(parseInt(data.status))">
                            <i :class="{
                                'pi pi-chevron-down': isRowExpanded(data),
                                'pi pi-chevron-right': !isRowExpanded(data)
                            }" @click="toggleRow(data)" style="cursor: pointer;"></i>
                        </div>
                        <div style="width: 20px; opacity: 0.6;" v-else>
                            <i class="pi pi-chevron-right"></i>
                        </div>
                    </template>
                </Column>

                <Column style="padding-left: 0" field="header">
                    <template #body="slotProps">
                        <!-- {{ slotProps }} -->
                        <div class="flex" style="justify-content: space-between; align-items: center;">
                            <div class="flex ml-2" style="gap: 20px; align-items: center;">
                                <div style="display: flex; flex-direction: column; align-items: start; gap: 5px;">
                                    <Chip class="secondaryChip" style="height: fit-content; width: 130px;"
                                        :label="slotProps.data.name" />
                                </div>

                                <div>
                                    <div style="font-size: 12px; font-weight: 400;">
                                        {{ this.$langGetValue("createDate") }}
                                    </div>
                                    <div class="flex mt-1" style="align-items: center; gap: 5px; font-weight: 800;">
                                        <i class="pi pi-calendar"></i>
                                        {{ convertDate(slotProps.data.createdTimestamp) }}
                                    </div>
                                </div>

                                <div style="height: 35px; border: 1px solid #9f9e9e;"></div>

                                <div>
                                    <div style="font-size: 12px; font-weight: 400;">
                                        {{ this.$langGetValue("user") }}
                                    </div>
                                    <div class="flex mt-1" style="align-items: center; gap: 5px; font-weight: 800;">
                                        <i class="pi pi-user"></i>
                                        {{ emailToName(slotProps.data.createdBy) }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex justify-content-between align-items-center" style="padding: 15px;">
                                <div class="flex mr-4" style="gap: 20px; align-items: center;"
                                    v-if="[3, 5, 6, 7].includes(parseInt(slotProps.data.status))">
                                    <div style="width: 120px">
                                        <div style="font-size: 12px; font-weight: 400;">
                                            {{ this.$langGetValue("totalLoss") }}
                                        </div>
                                        <div class="flex mt-1" style="align-items: center; gap: 5px; font-weight: 800;">
                                            {{ formatCurrency(slotProps.data.totalLossAmount) }}
                                        </div>
                                    </div>

                                    <div style="width: 50px">
                                        <div style="font-size: 12px; font-weight: 400;">
                                            {{ this.$langGetValue("events") }}
                                        </div>
                                        <div class="flex mt-1" style="align-items: center; gap: 5px; font-weight: 800;">
                                            {{ slotProps.data.eventCount }}
                                        </div>
                                    </div>
                                </div>

                                <div class="flex mr-4" style="width: 170px; align-items: center;" v-else>
                                </div>

                                <div class="flex" style="gap: 10px; background: white;
                            padding: 10px;
                            border-radius: 20px; align-items: center;">
                                    <Chip style="height: 30px; width: 180px; justify-content: center ; background: var(--color_gray_xlight); color: var(--color_gray_xdark);border: 1px solid var(--color_gray_light); box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                                        font-weight: 700;" :label="getStatus(slotProps.data.status)" />

                                    <div v-if="[1, 2, 5, 8, 9].includes(parseInt(slotProps.data.status))" class="flex"
                                        style="width: 100px; justify-content: space-evenly;">
                                        <button style="width: 2rem; height: 2rem;" disabled
                                            v-tooltip.top="{ value: this.$langGetValue('downloadXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-download"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('generateXML') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-cog"></i>
                                        </button>
                                        <button
                                            style="width: 2rem; height: 2rem; padding-left: 0px; padding-right: 0px; justify-content: center;"
                                            v-tooltip.top="{ value: this.$langGetValue('validate') }" disabled
                                            class="p-button p-component p-button-icson-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-check"></i>
                                        </button>
                                    </div>
                                    <div v-if="slotProps.data.status == 3" class="flex"
                                        style="width: 100px; justify-content: space-evenly;">
                                        <button style="width: 2rem; height: 2rem;" disabled
                                            v-tooltip.top="{ value: this.$langGetValue('downloadXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-download"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            @click="this.summaryID = slotProps.data.id; generateXMLDialog = true"
                                            v-tooltip.top="{ value: this.$langGetValue('generateXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-cog"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('validate') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-check"></i>
                                        </button>
                                    </div>
                                    <div v-if="slotProps.data.status == 4" class="flex"
                                        style="width: 100px; justify-content: space-evenly;">
                                        <button style="width: 2rem; height: 2rem;"
                                            @click="downloadError(slotProps.data)"
                                            v-tooltip.top="{ value: this.$langGetValue('downloadErrors') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-download"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('generateXML') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-cog"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            v-tooltip.top="{ value: this.$langGetValue('validate') }" disabled
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-check"></i>
                                        </button>
                                    </div>
                                    <div v-if="slotProps.data.status == 6" class="flex"
                                        style="width: 100px; justify-content: space-evenly;">
                                        <button style="width: 2rem; height: 2rem;" @click="downloadXML(slotProps.data)"
                                            v-tooltip.top="{ value: this.$langGetValue('downloadXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-download"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;" disabled
                                            v-tooltip.top="{ value: this.$langGetValue('generateXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-cog"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;"
                                            @click="this.summaryID = slotProps.data.id; validateSummaryDialog = true"
                                            v-tooltip.top="{ value: this.$langGetValue('validate') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-check"></i>
                                        </button>
                                    </div>
                                    <div v-if="slotProps.data.status == 7" class="flex"
                                        style="width: 100px; justify-content: space-evenly;">
                                        <button style="width: 2rem; height: 2rem;" @click="downloadXML(slotProps.data)"
                                            v-tooltip.top="{ value: this.$langGetValue('downloadXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-download"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;" disabled
                                            v-tooltip.top="{ value: this.$langGetValue('generateXML') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-cog"></i>
                                        </button>
                                        <button style="width: 2rem; height: 2rem;" disabled
                                            v-tooltip.top="{ value: this.$langGetValue('validate') }"
                                            class="p-button p-component p-button-icon-only p-button-secondary p-button-raised p-button-rounded p-button-text">
                                            <i class="pi pi-check"></i>
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </template>
                </Column>

                <template #expansion="slotProps">

                    <div style="overflow-x: auto; display: block; max-width: 100%;"
                        v-if="slotProps.data.status >= 3 && slotProps.data.status <= 7">
                        <div
                            style="text-align: left; margin-top: 20px;font-weight: bolder; font-size: 15px; margin-bottom: 10px;">
                            {{ this.$langGetValue('summaryBdpo') }}
                        </div>
                        <DataTable class="summaryTable" :value="slotProps.data.details.tableSummary.data"
                            tableStyle="min-width: 50rem">
                            <ColumnGroup type="header">
                                <Row>
                                    <Column :header="this.$langGetValue('baselBdpo')" :rowspan="2" />
                                    <Column v-for="col in slotProps.data.details.tableSummary.columns" :key="col"
                                        :header="col"
                                        :colspan="slotProps.data.details.tableSummary.subColumns.length" />
                                </Row>
                                <Row>
                                    <Column v-for="subcol in this.getSubColsSummary(slotProps.data)"
                                        :key="subcol.header" :header="this.$langGetValue(subcol.header)"
                                        :field="subcol.field" />
                                </Row>
                            </ColumnGroup>
                            <Column field="baselLevel"
                                style="background: #f8f9fa !important; font-weight: bold !important;" />
                            <Column v-for="subcol in getSubColsSummary(slotProps.data)" :key="subcol.header">
                                <template #body="slotProps">
                                    {{ getMaskedValueForTable(slotProps.data, subcol.field) }}
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div style="height:10px"></div>
                    <div style="overflow-x: auto; display: block; max-width: 100%;"
                        v-if="slotProps.data.status == 6 || slotProps.data.status == 7">
                        <div
                            style="text-align: left; margin-top: 20px;font-weight: bolder; font-size: 15px; margin-bottom: 10px;">
                            XML
                        </div>
                        <DataTable class="summaryTable xmlTable" :value="slotProps.data.details.tableXML.data"
                            tableStyle="min-width: 50rem">
                            <ColumnGroup type="header">
                                <Row>
                                    <Column :header="this.$langGetValue('baselBdpo')" :rowspan="2" />
                                    <Column v-for="col in slotProps.data.details.tableXML.columns" :key="col"
                                        :header="col" :colspan="slotProps.data.details.tableXML.subColumns.length" />
                                </Row>
                                <Row>
                                    <Column v-for="subcol in this.getSubColsXML(slotProps.data)" :key="subcol.header"
                                        :header="this.$langGetValue(subcol.header)" :field="subcol.field" />
                                </Row>
                            </ColumnGroup>
                            <Column field="baselLevel"
                                style="background: #f8f9fa !important; font-weight: bold !important;" />
                            <Column v-for="subcol in this.getSubColsXML(slotProps.data)" :key="subcol.header"
                                >
                                <template #body="slotProps">
                                    {{ getMaskedValueForTable(slotProps.data, subcol.field) }}
                                </template>
                            </Column>
                        </DataTable>
                    </div>
                    <div style="height:10px"></div>
                </template>
            </DataTable>

        </div>
        <div v-else
            style="margin: 64px 0px; display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <img src="images/not_found.svg" style="width: 100px; padding-bottom: 16px; padding-top: 16px;" />
            <div class="body2" style="width: 30%; display: flex; justify-content: center; text-align: center">
                {{ this.$langGetValue("errorNoSummaries") }}
            </div>
        </div>
    </div>
    <div style="width: 100%;" v-if="attachments != null">
        <AttachmentComponent :attachments="this.attachments"
            :attachmentsMode="checkRole(['*.storm.bdpo.*.file-upload']) == true ? 'edit' : 'readonly'"
            @returned-ids="setAttachments">
        </AttachmentComponent>
    </div>

    <Dialog class="summaryDialog" :showHeader="false" v-model:visible="generateSummaryDialog" style="width: 30vw"
        :modal="true">
        <Vue3Lottie ref="lottieContainer2" :animationData="thunderAnimation" :height="100" :width="100" />
        <div class="mt-3">
            <h5 style="margin-left: 20px; font-weight: bold">
                {{ this.$langGetValue('summaryBdpo') }}
            </h5>
            <p style="margin-left: 20px">
                {{ this.$langGetValue('clickToGenerate') }}
            </p>
        </div>
        <template #footer>
            <Button :label="this.$langGetValue('generate')" @click="generateSummaryDialog = false; generateSummary()"
                style="width: 90px; background: var(--color_brand_medium);" />
            <Button :label="this.$langGetValue('buttonClose')" @click="generateSummaryDialog = false;"
                style="width: 90px; background: var(--color_brand_medium);" />
        </template>
    </Dialog>

    <Dialog class="validateDialog" :showHeader="false" v-model:visible="validateSummaryDialog" style="width: 30vw"
        :modal="true">
        <Vue3Lottie ref="lottieContainer2" :animationData="thunderAnimation" :height="100" :width="100" />
        <div class="mt-3">
            <h5 style="margin-left: 20px; font-weight: bold">
                {{ this.$langGetValue('validation') }}
            </h5>
            <p style="margin-left: 20px">
                {{ this.$langGetValue('clickValidation') }}
            </p>
        </div>
        <template #footer>
            <Button :label="this.$langGetValue('validate')" @click="generateSummaryDialog = false; checkXML()"
                style="width: 90px; background: var(--color_brand_medium);" />
            <Button :label="this.$langGetValue('buttonClose')" @click="validateSummaryDialog = false;"
                style="width: 90px; background: var(--color_brand_medium);" />
        </template>
    </Dialog>


    <Dialog class="generateXMLDialog" style="width: 450px" v-model:visible="generateXMLDialog" modal
        :dismissable-mask="true">
        <div style="width: 100%; padding: 10px;">
            <div style="text-align: center;padding: 20px; font-size: 19px; font-weight: 600; ">
                {{ this.$langGetValue('generateXML') }}
            </div>
            <div>
                <div style="font-size: 14px; font-weight: 600;">
                    {{ this.$langGetValue('minimumValue') }}
                </div>
                <InputNumber class="mt-2" v-model="selectedOptions.minimumAmount" inputId="integeronly"
                    style="width: 100%;" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" />
            </div>
            <div style="margin-top: 25px;">
                <div style="font-size: 14px; font-weight: 600;" class="mb-2">
                    {{ this.$langGetValue("company") }}
                </div>
                <MultiSelect style="height: fit-content; width:100%" v-model="selectedOptions.companySelected"
                    optionLabel="label" :disabled="false" :options="filteredCompany" optionGroupLabel="label"
                    optionGroupChildren="children" :placeholder="this.$langGetValue('selectOption')"
                    :maxSelectedLabels="3" :filter="true" @filter="filterCompany">
                    <template #optiongroup="slotProps">
                        <div class="flex items-center">
                            <div>{{ slotProps.option.label }}</div>
                        </div>
                    </template>
                    <template #value>
                        <div class="flex" style="flex-wrap: nowrap;">
                            <span v-for="(value, index) in selectedOptions.companySelected.slice(0, 3)" :key="index">
                                {{ value.label }}<span
                                    v-if="index < Math.min(2, selectedOptions.companySelected.length - 1)">, </span>
                            </span>
                            <span v-if="selectedOptions.companySelected.length > 3">...</span>
                        </div>
                    </template>
                </MultiSelect>
            </div>
        </div>
        <template #footer>
            <div style="width:100%" class="text-center">
                <Button :label="this.$langGetValue('generateXML')" class="primaryButton" style="width:150px"
                    @click="generateXML" />
            </div>
        </template>
    </Dialog>
</template>

<script>
import "../Global.css";
import BdpoService from "../service/BdpoService";
import Util from "../service/Util";
import AttachmentComponent from "../components/AttachmentComponent.vue"
import FieldService from "../service/FieldService";
import UserService from "../service/UserService";
import { Vue3Lottie } from "vue3-lottie";
import thunderAnimation from "../assets/animations/AnimacaoThunder.json";

export default {
    components: {
        AttachmentComponent,
        Vue3Lottie
    },
    data() {
        return {
            thunderAnimation,
            expandedRows: [],

            bdpoOptions: {
                companyOptions: [],
                statusOptions: FieldService.getField('bdpo', 'bdpoSummaryStatus')
            },

            selectedOptions: {
                baseDate: null,
                selectedStatus: FieldService.getField('bdpo', 'bdpoSummaryStatus'),
                minimumAmount: null,
                companySelected: [],
            },
            summaryID: null,
            filteredCompany: null,
            generateSummaryDialog: false,
            validateSummaryDialog: false,
            generateXMLDialog: false,
            incidentList: null,
            attachments: null,
            loading: false,
            bdpoMappedCompanys: null,

            pages: parseInt(Util.pageSize),
            currentPage: 0,
            totalRecords: 0,
        };
    },
    created() {

    },
    beforeUnmount() {
        clearInterval(this.autoRefreshInterval);
    },
    async mounted() {

        await FieldService.getFieldSafe('incident', 'company').then((resp) => {
            this.bdpoOptions.companyOptions = resp
        })

        FieldService.getMap("bdpo").then((resp) => {
            if (resp.status < 400) {
                this.bdpoMappedCompanys = Object.keys(resp.data.company).map(Number)

                this.bdpoOptions.companyOptions.forEach(group => {
                    group.children = group.children.filter(child => this.bdpoMappedCompanys.includes(child.id));
                });
            }
        })

        this.filteredCompany = this.bdpoOptions.companyOptions

        this.autoRefreshInterval = setInterval(() => {
            this.getList();
        }, 90000);
    },
    watch: {
        'selectedOptions.baseDate': function () {
            this.currentPage = 0
            this.incidentList = null
            this.attachments = null
        },
    },
    methods: {
        toggleRow(data) {
            const index = this.expandedRows.indexOf(data);
            if (index === -1) {
                this.expandedRows.push(data);
            } else {
                this.expandedRows.splice(index, 1);
            }
        },
        isRowExpanded(data) {
            return this.expandedRows.includes(data);
        },
        setAttachments(att) {
            this.attachments = att

            let payload = {
                "attachment": this.attachments
            }

            BdpoService.patchReferenceDate(this.incidentList.extraFields.id, payload).then((response) => {
                if (response.status != 200) {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast8"),
                        life: 5000,
                    });
                    return
                }
            })
        },
        searchList() {
            if (this.selectedOptions.selectedStatus.length == 0 || this.selectedOptions.baseDate == null) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue("blankFields"),
                    life: 5000,
                });
                return
            }
            this.getList()
        },
        paginate(event) {
            if (event.rows != this.pages) {
                this.currentPage = 0
                Util.setRemoteStorage("pagesize", event.rows).then(() => {
                    this.pages = event.rows;
                    Util.pageSize = event.rows
                    this.getList()
                });
            }
            else {
                this.currentPage = event.page;
                this.getList()
            }
        },
        async getList(newResume = false) {
            if (this.selectedOptions.selectedStatus.length == 0 || this.selectedOptions.baseDate == null) {
                return
            }

            let payload = {
                "orderBy": [
                    {
                        "field": "id",
                        "direction": "DESC"
                    }
                ],
                "sqlWhereClause": {
                    "condition": "OR",
                    "rules": [
                    ]
                },
                "page": this.currentPage + 1,
                "pageSize": this.pages
            }

            if (newResume) {
                this.selectedOptions.selectedStatus = this.bdpoOptions.statusOptions
            }

            this.selectedOptions.selectedStatus.forEach((status) => {
                payload.sqlWhereClause.rules.push({
                    "field": "status",
                    "type": "integer",
                    "operator": "equal",
                    "value": status.id
                })
            })

            let baseDate = this.selectedOptions.baseDate;

            let nextMonth = new Date(baseDate.getFullYear(), baseDate.getMonth() + 1, 1);

            let lastDayOfMonth = new Date(nextMonth - 1).getDate();

            let formattedDate = `${this.selectedOptions.baseDate.getFullYear()}-${String(this.selectedOptions.baseDate.getMonth() + 1).padStart(2, '0')}-${String(lastDayOfMonth)}`;

            this.loading = true
            BdpoService.listBdpo(formattedDate, payload).then((response) => {
                this.loading = false
                if (response.status == 200) {
                    this.totalRecords = response.data.totalRecords
                    this.refreshData(response.data)
                    return
                }
                if (response.status == 404) {
                    this.createReferenceDate(formattedDate)
                    return
                }

                this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue("toast6"),
                    details: this.$langGetValue("toast6sub"),
                    life: 5000,
                });
                return
            })
        },
        createReferenceDate(referenceDate) {
            let payload = {
                "companyRole": UserService.company,
                "referenceDate": referenceDate
            }

            BdpoService.createReferenceDate(payload).then((response) => {
                this.refreshData(response.data)
            })
        },
        refreshData(data) {

            this.expandedRows = []

            // this.incidentList = null

            // Ainda tá piscando. ajustar

            this.incidentList = data

            // this.removeHeader()
            if (this.attachments != data.extraFields.attachment) {
                this.attachments = null

                this.$nextTick(() => {
                    this.attachments = data.extraFields.attachment
                })
            }

            // this.incidentList = data


            // this.$nextTick(() => {

            // })
        },
        removeHeader() {
            setTimeout(() => {
                if (this.incidentList.records.length == 0) {
                    return
                }

                const summaryTable = document.querySelector(".bdpoSummaryDataTable");

                console.log(summaryTable)

                if (summaryTable) {
                    const firstThead = summaryTable.querySelector("thead");

                    if (firstThead) {
                        firstThead.remove();
                    }
                }
            }, 10)
        },
        getColor(status) {
            if (status == 1) {
                return '#ffbc00' //amarelo
            }
            if (status == 2) {
                return 'green'
            }
            if (status == 3) {
                return 'red'
            }
        },
        generateXML() {
            if (this.selectedOptions.minimumAmount == null || this.selectedOptions.companySelected.length == 0) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue("toast7"),
                    life: 5000,
                });
                return
            }

            let payload = {
                "minThresholdAmount": this.selectedOptions.minimumAmount,
                "company": this.selectedOptions.companySelected.map(company => company.id)
            }

            BdpoService.generateXML(payload, this.summaryID).then((response) => {
                this.generateXMLDialog = false
                this.summaryID = null

                if (response.status < 400) {
                    this.getList()
                    return
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast6"),
                        details: this.$langGetValue("toast6sub"),
                        life: 5000,
                    });
                    return
                }
            })
        },
        downloadError(data) {
            Util.loading = true
            BdpoService.downloadError(data.id).then((response) => {
                Util.loading = false
                if (response.status == 200) {
                    let downloadLink = response.data.links[0].href;
                    let link = document.createElement('a');
                    link.href = downloadLink;
                    link.download = true;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast6"),
                        details: this.$langGetValue("toast6sub"),
                        life: 5000,
                    });
                    return
                }
            })
        },
        downloadXML(data) {
            BdpoService.downloadXML(data.id).then((response) => {
                if (response.status < 400) {
                    console.log(response.data)
                    let downloadLink = response.data.urlPresigned;
                    let link = document.createElement('a');
                    link.href = downloadLink;
                    link.download = true;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    return
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast6"),
                        details: this.$langGetValue("toast6sub"),
                        life: 5000,
                    });
                    return
                }
            })
        },
        checkXML() {
            BdpoService.checkXML(this.summaryID).then((response) => {
                this.validateSummaryDialog = false
                if (response.status < 400) {
                    this.$toast.add({
                        severity: "success",
                        summary: this.$langGetValue("dialog0"),
                        life: 5000,
                    });
                    return
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast6"),
                        details: this.$langGetValue("toast6sub"),
                        life: 5000,
                    });
                    return
                }
            })
        },
        convertDate(date) {
            return Util.formatDate(date, true)
        },
        emailToName(mail) {
            return Util.emailToName(mail)
        },
        generateSummary() {

            if (this.selectedOptions.baseDate == null) {
                this.$toast.add({
                    severity: "error",
                    summary: this.$langGetValue("toast7"),
                    life: 5000,
                });
                return
            }

            Util.loading = true
            BdpoService.generateSummary(this.incidentList.extraFields.id).then((response) => {
                Util.loading = false
                if (response.status < 400) {
                    this.getList(true)
                }
                else {
                    this.$toast.add({
                        severity: "error",
                        summary: this.$langGetValue("toast6"),
                        details: this.$langGetValue("toast6sub"),
                        life: 5000,
                    });
                    return
                }
            })
        },
        filterCompany(event) {
            if (event.value.trim().length === 0) {
                this.filteredCompany = this.bdpoOptions.companyOptions;
                return;
            }

            let tempCompany = [];

            this.bdpoOptions.companyOptions.forEach((group) => {
                let matchedChildren = group.children.filter((child) =>
                    child.label.toLowerCase().includes(event.value.toLowerCase())
                );

                if (matchedChildren.length > 0) {
                    let tempGroup = { ...group };
                    tempGroup.children = matchedChildren;
                    tempCompany.push(tempGroup);
                }
            });

            this.filteredCompany = [...tempCompany];
        },
        getSubColsSummary(data) {
            let subcols = [];
            data.details.tableSummary.columns.forEach((col) => {
                data.details.tableSummary.subColumns.forEach((subCol) => {
                    subcols.push({ field: "years." + col + "." + subCol, header: subCol });
                });
            });
            return subcols;
        },
        getSubColsXML(data) {
            let subcols = [];
            data.details.tableXML.columns.forEach((col) => {
                data.details.tableXML.subColumns.forEach((subCol) => {
                    subcols.push({ field: "years." + col + "." + subCol, header: subCol });
                });
            });
            return subcols;
        },
        getStatus(status) {
            return this.bdpoOptions.statusOptions.filter(a => a.id == status)[0].label
        },
        checkRole(roles) {
            return UserService.checkUserRoleAny(roles)
        },
        formatCurrency(amount) {
            // Garante que o valor seja um número
            if (isNaN(amount)) {
                return '0,00';  // Retorna '0,00' se o valor não for um número
            }

            // Formata o valor como moeda em BRL
            return amount.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
        },
        getMaskedValueForTable(data, path) {
            // Verifica se o caminho contém 'amount'
            if (path.includes('amount')) {
                const value = path.split('.').reduce((obj, key) => {
                    return obj && obj[key] !== undefined ? obj[key] : null; // ou um valor padrão que você preferir
                }, data);

                // Se o valor não for nulo, formate como moeda
                return value !== null ? this.formatCurrency(value) : 'N/A';
            } else {
                // Para outros casos, apenas retorna o valor sem formatação
                return path.split('.').reduce((obj, key) => {
                    return obj && obj[key] !== undefined ? obj[key] : 'N/A'; // ou um valor padrão que você preferir
                }, data);
            }
        },
    },
};
</script>
<style>
/* .bdpoSummaryDataTable th {
    display: none;
} */

.bdpoSummaryDataTable .p-datatable-tbody tr td {
    padding-top: 0px;
    padding-bottom: 0px;
}

.bdpoSummaryDataTable .p-datatable-loading-overlay {
    display: none;
}

/* .bdpoSummaryDataTable .p-datatable-row-expansion {
    background: #f7f7f7 !important;
} */

.customCalendarBdpo {
    height: 50px;
    border-radius: 4px;
}

.customDropdown {
    border-width: 0px 0px 1px 0px;
    border-radius: 0px;
}

.generateXMLDialog .p-dialog-header {
    justify-content: end;
}

.summaryDialog .p-dialog-content {
    border-radius: 12px 12px 0px 0px;
}

.summaryDialog .p-dialog-footer {
    border-radius: 0px 0px 12px 12px;
}

.validateDialog .p-dialog-content {
    border-radius: 12px 12px 0px 0px;
}

.validateDialog .p-dialog-footer {
    border-radius: 0px 0px 12px 12px;
}

.summaryTable .p-column-header-content {
    justify-content: center;
}

/* .summaryTable th {
    background: #f8f9fa !important;
    font-weight: bold !important;
    border: 0.5px solid rgb(201, 201, 201) !important;
     color: rgb(48, 48, 48) !important; 
} */

.summaryTable td {
    padding: 5px !important;
    text-align: center !important;
    border: 0.5px solid rgb(201, 201, 201) !important;
}

.generateXMLDialog .p-dialog-header {
    display: none !important
}

.generateXMLDialog .p-dialog-content {
    border-radius: 8px 8px 0px 0px;
}

/* .bdpoSummaryDataTable th {
    background: white;
} */

.bdpoSummaryDataTable .summaryTable th {
    background: #f8f9fa !important;
    font-weight: bold !important;
    border: 0.5px solid rgb(201, 201, 201) !important;
    /* color: rgb(48, 48, 48) !important; */
}
</style>